/*
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-04-20 09:02:50
 * @LastEditTime: 2022-06-11 10:09:33
 */
import axios from '@/utils/request'
const Api = {
	summerList: "/api/backyard/summerProgram/list",
	summerAdd: "/api/backyard/summerProgram/",
	summerEdit: "/api/backyard/summerProgram/",
	summerDetail: "/api/backyard/summerProgram/",
	schoolList: "/api/backyard/school/list",
    schoolAdd: "/api/backyard/school/save",
	schoolDelete: "/api/backyard/school/delete",
  summerDetail: "/api/backyard/summerProgram/",
  summerDel: "/api/backyard/summerProgram/",
	schoolList: "/api/backyard/school/list",
	activityList: "/api/backyard/activity/list",
  activityAdd: "/api/backyard/activity/",
  activityEdit: "/api/backyard/activity/",
  activityDetail: "/api/backyard/activity/",
  activityDel: "/api/backyard/activity/"
}
// 获取夏校库列表
export function listSummer(params) {
	return axios({
		method: 'get',
		url: Api.summerList,
		params: params
	})
}
// 添加夏校库
export function addSummer(params) {
	return axios({
		method: 'post',
		url: Api.summerAdd,
		data: params
	})
}
// 更新夏校库
export function editSummer(params, id) {
	return axios({
		method: 'put',
		url: Api.summerEdit + id,
		data: params
	})
}
// 删除夏校库
export function delSummer(params) {
	return axios({
		method: 'delete',
		url: Api.summerDel + params,
		params: params
	})
}
export function delActivity (params) {
  return axios({
    method: 'delete',
    url: Api.activityDel + params,
    params: params
  })
}
// 通过id获取夏校库详情
export function detailSummer(params) {
	return axios({
		method: 'get',
		url: Api.summerDetail + params,
	})
}
// 获取学校库列表
export function listSchool(params) {
	return axios({
		method: 'get',
		url: Api.schoolList,
		params: params
	})
}
// 学校库添加
export function addSchool(params) {
	return axios({
		method: 'post',
		url: Api.schoolAdd,
		data: params
	})
}
// 学校库删除
export function deleteSchool(params) {
	return axios({
		method: 'get',
		url: Api.schoolDelete,
		params: params
	})
}
// 获取活动库列表
export function listActivity (params) {
  return axios({
    method: 'get',
    url: Api.activityList,
    params: params
  })
}
// 添加活动库
export function addActivity (params) {
  return axios({
    method: 'post',
    url: Api.activityAdd,
    data: params
  })
}
// 更新夏校库
export function editActivity (params,id) {
  return axios({
    method: 'put',
    url: Api.activityEdit + id,
    data: params
  })
}
// 通过id获取夏校库详情
export function detailActivity (params) {
  return axios({
    method: 'get',
    url: Api.activityDetail + params,
  })
}