<template>
	<div class="activity">
		<div class="activity_tab">
			<div @click="backTo" class="activity_back">
				<img src="@/assets/images/knowledgeBase/back.png">
				<span>返回</span>
			</div>
			<module-list :moduleList="moduleList" :currentTab="currentTab" @changeTab="changeTab" />
		</div>
		<div class="activity_content">
			<div class="activity_content_title">
				{{visitType == 'add' ? '添加活动' : '编辑活动'}}
			</div>
			<div class="activity_content_form">
				<div class="handle_form">
					<el-form ref="activityDetail" :rules="activityRules" :model="activityDetail" label-width="80px">
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item class="flex_item" label="活动标题:" prop="title" ref="title">
									<input v-model="activityDetail.title" placeholder="请输入活动标题" @blur="inputBlur($event)" class="handle_form_ipt"></input>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item class="flex_item" label="活动类型:" prop="activityType">
									<el-select v-model="activityDetail.activityType" class="handle_form_select" clearable
										placeholder="请选择">
										<el-option v-for="item in activityType" :key="item.value" :label="item.label"
											:value="item.value">
										</el-option>
									</el-select>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item class="" label="封面图片:" prop="cover" ref="uploadCover">
									<div style="margin-bottom: 6px;">
										<dragUpLoad ref="dragUpLoad" :isLimitPx="true" @beforeUpload="beforeUpload" @removeImg="removeImg" />
									</div>
								    <span style="color: #888888;">注：图片宽高限制最小为（340x170），其他格式请保持宽高比例为2:1，否则无法上传！</span>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item class="" label="内容:" prop="content" ref="editor">
									<quillEditor @editorChange="editorChange" :intEditor.sync="activityDetail.content"  class="handle_form_editor"/>
								</el-form-item>
							</div>
						</div>
					</el-form>
					<div class="handle_form_btn">
						<span @click="backTo" class="handle_form_cancel">取消</span>
						<span @click="submitActivity" class="handle_form_confirm">发布</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		addActivity,
		editActivity,
		detailActivity
	} from "@/api/knowledgeBase"
	import moduleList from "@/components/moduleList/index.vue";
	import quillEditor from "@/components/quillEditor/index.vue";
	import dragUpLoad from "@/components/dragUpLoad/index.vue";
	import storage from 'store';
	export default {
		components: {
			moduleList,
			quillEditor,
			dragUpLoad
		},
		data() {
			const checkCover = (rule, value, callback) => {
				if (this.activityDetail.cover == '') {
					callback(new Error("请上传封面图片"));
				} else {
					callback();
				}
				callback();
			};
			const checkContent = (rule, value, callback) => {
				if (this.activityDetail.content == '') {
					callback(new Error("请输入内容"));
				} else {
					callback();
				}
				callback();
			};
			return {
				currentTab: '',
				moduleList: [
				],
				visitType: "",
				imgSrc:[],
				activityId:"",
				activityDetail: {
					title: "",
					cover: "",
					content: "",
					activityType:""
				},
				activityRules: {
					title: [{
						required: true,
						message: '请输入活动标题',
						trigger: 'change'
					}],
					cover: [{
						required: true,
						validator: checkCover,
						trigger: 'change'
					}],
					activityType: [{
						required: true,
						message: '请选择活动类型',
						trigger: 'change'
					}],
					content: [{
						required: true,
						validator: checkContent,
						trigger: 'change'
					}]
				},
				activityType: [{
						label: 'STEM',
						value: "STEM"
					},
					{
						label: '人文社科',
						value: "HUMANITIES"
					},
					{
						label: '商业',
						value: "COMMERCIAL"
					},
					{
						label: '志愿者',
						value: "VOLUNTEER"
					}, {
						label: '其他',
						value: "OTHERS"
					},
				],
			}
		},
		computed: {
		},
		created() {
			this.currentTab = this.$route.query.currentTab;
			this.visitType = this.$route.query.visitType;
			if(this.visitType == 'edit'){
				this.activityId = this.$route.query.activityId;
				this.getActivityDetail(this.activityId)
			}
			if(storage.get("moduleList")) {
				this.moduleList = storage.get("moduleList")
			}

		},
		mounted() {
			
		},
		methods: {
			backTo() {
				this.$router.push({
					path: `/knowledgeBase?currentTab=${this.currentTab}`,
				});
			},
			//根据id获取详情信息
			getActivityDetail(id){
				detailActivity(id).then(res=>{
					if(res.code == 0){
						this.activityDetail = res.data
						this.$refs.dragUpLoad.fileData = [res.data.cover]
					}
				})
			},
			changeTab(props) {
				this.currentTab = props.label;
				this.backTo()
			},
			beforeUpload(props) {
				if (props.length > 0) {
					this.activityDetail.cover = props[0]
					this.$refs.uploadCover.clearValidate()
				} else {
					this.activityDetail.cover = ""
				}
			},
			removeImg(props) {
				if (props.length > 0) {
					this.activityDetail.cover = props[0]

				} else {
					this.activityDetail.cover = ""
				}
			},
			editorChange(props) {
				this.activityDetail.content = props
				if(props){
					this.$refs.editor.clearValidate()
				}
			},
			inputBlur(event){
				if(event.target.value!==''){
					this.$refs.title.clearValidate()
				}
			},
			submitActivity() {
				this.$refs["activityDetail"].validate(valid => {
					if (valid) { 
						let activityForm = JSON.parse(JSON.stringify(this.activityDetail))
						if (this.visitType == 'add') {
							addActivity(activityForm).then((res) => {
								if(res.code == 0) {
									this.$message({
										message: "活动添加成功~",
										type: 'success'
									})
									setTimeout(() => {
										this.backTo()
									}, 500)
								}
							})
						}else{
							editActivity(activityForm,this.activityId).then((res) => {
								if(res.code == 0) {
									this.$message({
										message: "活动编辑成功~",
										type: 'success'
									})
									setTimeout(() => {
										this.backTo()
									}, 500)
								}
							})
						}
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import "../../styles/index.scss";
	@import "./components/common.scss";
	.activity {
		display: flex;
		flex-direction: column;

		.activity_tab {
			padding-left: 932px;
			position: relative;

			.activity_back {
				position: absolute;
				left: 0;
				top: 11px;
				width: 100px;
				height: 34px;
				background: #FFFFFF;
				box-shadow: 0px 3px 6px rgba(153, 153, 153, 0.16);
				border-radius: 0px 17px 17px 0px;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;

				img {
					width: 20px;
					height: 20px;
					margin-right: 10px;
				}

				span {
					font-size: 16px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 20px;
					color: #333333;
				}
			}

			.activity_back :hover {
				color: $theme_color;
			}
		}
		
		.activity_content {
			background: #FFFFFF;
			border-radius: 10px 10px 0px 0px;
			padding: 20px 20px;
			display: flex;
			flex-direction: column;
			.activity_content_title {
				font-size: 20px;
				font-family: Source Han Sans CN;
				font-weight: bold;
				line-height: 20px;
				color: #333333;
				padding-left: 20px;
				border-left: 4px solid $theme_color;
			}
			.activity_content_form {
				.handle_form_row {
					margin-top: 25px;
				}
			}
		}
	}
</style>
