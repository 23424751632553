<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-03-18 09:45:39
 * @LastEditTime: 2022-03-18 10:56:54
-->
<template>
	<div class="quillEditor">
		<quill-editor class="quillEditor_inner" :style="{'height': editorHeight}" ref="QuillEditor"
			:options="editorOption" v-model="editorData" @change="editorChange">
		</quill-editor>
		<el-upload class="uploadImage" style="display:none;" :action="upLoadUrl" :show-file-list="false"
			:before-upload="beforeUpload"></el-upload>
	</div>
</template>
<script>
	import Quill from "quill";
	import {
		getImgSrc,
		upLoadFile
	} from "@/api/OSS";
	export default {
		props: {
			editorHeight: {
				type: String,
				default: function() {
					return "500px";
				}
			},
			limitSize: {
				//限制多少MB
				type: Number,
				default: 5
			},
			intEditor: {
				type: String,
				default: function() {
					return "";
				}
			}
		},
		watch: {
			intEditor: {
				handler(val) {
					this.editorData = val
				},
				deep: true
			}
		},
		data() {
			return {
				editorOption: {
					placeholder: "请输入内容......",
					modules: {
						toolbar: {
							container: [
								["bold", "italic", "underline", "strike"], // 加粗，斜体，下划线，删除线
								["blockquote", "code-block"], // 引用，代码块
								[{
										header: 1
									},
									{
										header: 2
									}
								], // 标题，键值对的形式；1、2表示字体大小
								[{
										list: "ordered"
									},
									{
										list: "bullet"
									}
								], // 列表
								[{
										script: "sub"
									},
									{
										script: "super"
									}
								], // 上下标
								[{
										indent: "-1"
									},
									{
										indent: "+1"
									}
								], // 缩进
								[{
									direction: "rtl"
								}], // 文本方向
								[{
									size: ["small", false, "large", "huge"]
								}], // 字体大小
								[{
									header: [1, 2, 3, 4, 5, 6, false]
								}], // 几级标题
								[{
										color: []
									},
									{
										background: []
									}
								], // 字体颜色，字体背景颜色
								[{
									font: []
								}], // 字体
								[{
									align: []
								}], // 对齐方式
								["clean"], // 清除字体样式
								["image", "link"] // 上传图片、上传视频
							],
							handlers: {
								image: value => {
									if (value) {
										document.querySelector(".uploadImage input").click();
										//   console.log(value)
									} else {
										this.quill.format("image", false);
									}
								},
							}
						}
					}
				},
				upLoadUrl: "",
				editorData: ""
			};
		},
		async mounted() {
			this.$refs.QuillEditor.quill.enable(false);
			this.$nextTick(function() {
				this.$refs.QuillEditor.quill.enable(true);
				this.$refs.QuillEditor.quill.blur();
			});
		},
		methods: {
			editorChange({
				quill,
				html,
				text
			}) {
				// console.log("editor change!", quill, html, text);
				this.$emit("editorChange", html);
			},
			// 上传按钮   限制图片大小
			beforeUpload(file) {
				console.log("beforeUpload", file);
				const isLt5M = file.size / 1024 / 1024 < this.limitSize;
				if (!isLt5M) {
					this.$message.error("上传文件大小不能超过 5MB!");
					return false;
				}
				let fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);
				let fileName = file.name;
				let fileType = file.type;
				if (fileSuffix != "png" && fileSuffix != "jpg" && fileSuffix != "jpeg") {
					this.$message({
						message: "上传文件只能是jpg、png格式!",
						type: "warning"
					});
					return false;
				}
				getImgSrc({
					fileName: fileName
				}).then((res) => {
					if (res.code != 0) return;
					this.upLoadUrl = res.data.uploadUrl;
					console.log("获取文件上传地址>>>>>", res.data.uploadUrl);
					upLoadFile(file, this.upLoadUrl, fileType).then((res) => {
						const fileUrl = res.config.url.substring(
							0,
							res.config.url.indexOf("?")
						);
						let quill = this.$refs.QuillEditor.quill;
						// 获取光标所在位置
						let range = quill.getSelection(true);
						let indexP = range.index;
						quill.insertEmbed(indexP, "image", fileUrl);
						quill.setSelection(indexP + 1);
					});
				});
			},
		}
	};
</script>
<style lang="scss" scoped>
	/deep/.quill-editor {
		display: flex;
		flex-direction: column;
	}
	
	/deep/img {
		width: 300px;
		height: auto;
	}

	.quillEditor {
		display: flex;
	}

	.quillEditor_inner {
		width: 100%;
	}
</style>
